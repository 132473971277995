import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../theme'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import PopupDialog from '../../components/PopupDialog'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import { Grid } from '@material-ui/core'
import { CONVEYOR_BELT_LIST_REQUEST } from '../../store/conveyor-belt/conveyorBeltActions'
import { toast } from 'react-toastify'
import Pagination from '@material-ui/lab/Pagination'
import ReadMoreReact from 'read-more-react'
import Loader from '../../components/Ux/Loader'
import useTogglePageSearch from '../../components/hooks/useTogglePageSearch'
import { Link, useRouteMatch } from 'react-router-dom'
import useSetMessageIcon from '../../components/hooks/useSetMessageIcon'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
})

const ListConveyorBelt = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const match = useRouteMatch()
  const [openDialog, setOpenDialog] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [newConveyorBelts, setNewConveyorBelts] = useState([])
  const isMount = useRef(false)
  const isErrorMount = useRef(false)
  const { profile } = useSelector((state) => state.user)
  const search = useSelector((state) => state.utility.search)
  const prevSearch = useRef('')
  const updateSearch = useRef('')
  const updateInterval = useRef(null)
  useSetMessageIcon()
  const handleConfirmDeleteCustomer = (e) => {
    setOpenDialog(false)
  }
  const conveyorBelts = useSelector((state) => state.conveyorBelt)
  const { cenveyorBelts, error, page, perPage, totalPages } = conveyorBelts
  useTogglePageSearch(CONVEYOR_BELT_LIST_REQUEST, { id: profile.customer_id, 'q[sorts]': 'name asc' }, 'serial_number_or_name')
  // On Component Mount getting the customer data
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CONVEYOR_BELT_LIST_REQUEST, payload: { id: profile.customer_id, data: { page, per_page: perPage, 'q[sorts]': 'name asc' } } })
    updateInterval.current = setInterval(() => {
      onUpdate(null, page)
    }, 10000)
    return () => {
      if (updateInterval.current) { clearInterval(updateInterval.current) }
    }
  }, [])

  useEffect(() => {
    if (isMount.current) {
      setShowLoader(false)
      setNewConveyorBelts(cenveyorBelts)
    } else {
      isMount.current = true
    }
  }, [cenveyorBelts])

  useEffect(() => {
    if (isErrorMount.current) {
      toast.error(error || t('error.something_went_wrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  useSetParentCustomInnerHeaderHeading()

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage }
    if (search.searchText) {
      payload['q[serial_number_or_name_cont]'] = search.searchText
    }
    setShowLoader(true)
    dispatch({
      type: CONVEYOR_BELT_LIST_REQUEST,
      payload: {
        id: profile.customer_id,
        data: {
          ...payload,
          'q[sorts]': 'name asc'
        }
      }
    })
  }

  const onUpdate = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage }

    if (updateSearch.current) {
      payload['q[serial_number_or_name_cont]'] = updateSearch.current
    }
    dispatch({
      type: CONVEYOR_BELT_LIST_REQUEST,
      payload: {
        id: profile.customer_id,
        data: {
          ...payload,
          'q[sorts]': 'name asc'
        }
      }
    })
  }

  useEffect(() => {
    updateSearch.current = search.searchText
    if (search.searchText) {
      prevSearch.current = search.searchText
      setShowLoader(true)
      return
    }

    if (!search.searchText && prevSearch.current) {
      setShowLoader(true)
    }
  }, [search])

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'} border={0}>
        <CardContent>
          <Loader open={showLoader} />
          <Grid container alignContent='space-between'>
            <Grid container direction='row' alignItems='stretch' className='RowDataHead pl-1 pr-1'>
              <Grid item xs={3} className='TextCenter'>
                <strong>
                  {t('cenveyor_belts.conveyorName')}
                </strong>
              </Grid>

              <Grid item xs={8} className='TextCenter'>
                <strong>
                  {t('cenveyor_belts.controlId')}
                </strong>
              </Grid>

              <Grid item xs={1} className='TextCenter cancel-icon-col' />
            </Grid>
          </Grid>
          <Grid item xs='12' className='Inner_scrollTwo'>

            <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
              {!showLoader ? newConveyorBelts.map((cenveyor) => (
                <Link
                  to={{ pathname: `${match.path}menu/${cenveyor.id}/` }}
                >
                  <Grid container key={cenveyor.id} direction='row' alignItems='stretch' justifyContent='space-between' className='RowData TokenUpRow RowHeight70'>

                    <Grid item xs={3} className='TextLeft dflex align-items-center PrimaryColor WordWrap'>
                      <strong>
                        {cenveyor && cenveyor.name && <ReadMoreReact
                          text={cenveyor.name}
                          min={30}
                          ideal={30}
                          max={30}
                          readMoreText='...'
                        />}
                      </strong>
                    </Grid>

                    <Grid item xs={8} className='TextLeft dflex align-items-center PrimaryColor'>
                      {cenveyor && cenveyor.serial_number && cenveyor.serial_number}
                    </Grid>
                    <Grid
                      item xs={1}
                      justifyContent='center'
                      className='TextLeft dflex align-items-center cancel-icon-col'
                      style={{ paddingRight: '0', paddingLeft: '0' }}
                    >
                      {cenveyor && cenveyor.online
                        ? <img style={{ width: '30px' }} src='/assets/img/signal.png' />
                        : <img style={{ width: '30px' }} src='/assets/img/no-signal.png' />}
                    </Grid>

                  </Grid>
                </Link>
              )) : null}
            </div>
          </Grid>
          {!cenveyorBelts.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap' style={{ flexDirection: 'column', alignItems: 'center' }}>
          {cenveyorBelts.length && totalPages
            ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
        </CardActions>
      </Card>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteCustomer} />
    </>
  )
}

export default Sidebars(ListConveyorBelt, { showSearch: true })
